
import { computed, ComputedRef, defineAsyncComponent, defineComponent, onMounted, ref } from "vue";
import Folder from "@/types/Folder";
import { useFoldersStore } from "@/stores/folders";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import router from "@/router";
import { AxiosResponse } from "axios";
import { useRoute } from "vue-router";
import { arrayHasBasedOnId, arrayToggleBaseOnId, isActionAvailable } from '@/functions/utils';

export default defineComponent({
  name: "Folders",
  components: {
    Header: defineAsyncComponent(() => import('@/components/Header.vue')),
    CardButton: defineAsyncComponent(() => import('@/components/CardButton.vue')),
    Checkbox: defineAsyncComponent(() => import('@/components/Checkbox.vue')),
    Loader: defineAsyncComponent(() => import('@/components/Loader.vue')),
    Card: defineAsyncComponent(() => import('@/components/Card.vue')),
    CardFilter: defineAsyncComponent(() => import('@/components/CardFilter.vue')),
    Input: defineAsyncComponent(() => import('@/components/Input.vue')),
    TableItem: defineAsyncComponent(() => import('@/components/TableItem.vue')),
    TableItemSkeleton: defineAsyncComponent(() => import('@/components/TableItemSkeleton.vue')),
    //CustomFilters: defineAsyncComponent(() => import('@/components/CustomFilters.vue')),
  },
  setup() {
    //ROUTER
    const route = useRoute();

    //STORE folders
    const foldersStore = useFoldersStore();
    const { folders, folderLoader } = storeToRefs(foldersStore);
    //STORE user
    const usersStore = useUserStore();
    const { user } = storeToRefs(usersStore);
    //DATA
    const loader = ref(true);
    const loaderErrorMsg = ref('');
    const isCreateFolderOpen = ref(false);
    const isUploadFileOpen = ref(false);
    const isCustomFiltersOpen = ref(false);
    const foldersCheck = ref<Array<Object>>([]);

    //DISPLAY FOLDER
    const searchTerm = ref('');
    const customerTerm = ref('');
    const foldersToDisplay: ComputedRef<Array<Object>> = computed(() => {
      let result = folders.value.filter((folder) => folder.state === 'termine');
      if (searchTerm.value !== '') result = result.filter((folder: Folder) => folder.name.includes(searchTerm.value));
      if (customerTerm.value !== '') result = result.filter((folder: Folder) => folder.customerCompany.includes(customerTerm.value));
      return result.map((item: any) => {
        const logo = "fa-solid fa-folder"
        const actions = [] as Array<Object>
        const fields = [
          { title: 'Dossier', value: item.name },
          { title: 'Entreprise', value: item.customerCompany },
        ]
        return { id: item.id, name: item.name, nbFile: item.nbFile, logo: logo, fields: fields, actions: actions, state: item.state };
      });
    });

    const toggleFoldersCheck = () => {
      if (foldersCheck.value.length === 0) {
        foldersToDisplay.value.forEach((folder: any) => arrayToggleBaseOnId(foldersCheck.value, folder));
      } else {
        foldersCheck.value = [];
      }
    };

    onMounted(() => {
      foldersStore.goLoader();
      foldersStore.getFolders().then(() => {
        foldersStore.stopLoader();
      })
    });

    return {
      customerTerm, user, folders,
      foldersToDisplay, loader, loaderErrorMsg, toggleFoldersCheck, foldersCheck,
       searchTerm, isCreateFolderOpen, isActionAvailable,
      isCustomFiltersOpen,
      isUploadFileOpen, folderLoader, arrayHasBasedOnId, arrayToggleBaseOnId
    };
  },
});
